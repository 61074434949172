import { Vue, Component, Watch, Prop, Inject } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import plannerStore from '@/store/modules/plannerStore'
import APP_CONST from '@/constants/AppConst'
import 'vue-cal/dist/vuecal.css';
import APP_UTILITIES from '@/utilities/commonFunctions';
import programConfigureStore from '@/store/modules/programConfigure'
import AssignTask from './../../components/assignTask/AssignTask.vue';
import SideNav from '../../commonComponent/sideNav/SideNav.vue';
import TaskItem from '../../components/taskItem/TaskItem.vue';
import EventItem from '../../components/eventItem/EventItem.vue';
import { DownloadTeamRosterCSV, getOwnersByAccountId } from "@/services/userService/users-api";
import { AddSiteDTO } from '@/services/program/adminService';
import { putNewOwner, updateTaskStatus, getTaskDetails, printHTMLTOPdf, updateSiteNotificationInfo } from "@/services/program/plannerService";
import UIkit from "uikit";
import programPlannerStore from '@/store/modules/programPlannerStore';
import ProgramPlannerWelcome from '../../components/programplannerwelcome/ProgramPlannerWelcome.vue'
import OperateTask from '../../components/operateTasks/OperateTask.vue';
import TaskPrintComponent from '../../components/taskPrintComponent/TaskPrintComponent.vue';
import Events from '../../components/Events/Events.vue';
import EventsPrint from '../../components/PrintDetailsEvent/EventsPrint.vue';
import modal from '../../components/modal/modal.vue';
import CalenderTaskItem from '../../components/calenderTaksItem/CalenderTaskItem.vue';
import PopupWrapperComponent from '@/commoncomponents/popupwrapper/PopupWrapperComponent.vue';
import CalenderEventItem from '../../components/calenderEventItem/CalenderEventItem.vue';
import PlannerPrintComponent from '../../components/plannerPrintComponent/PlannerPrintComponent.vue';
import html2canvas from 'html2canvas';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import Header from '../../components/calenderEventItem/PrintHeader.vue';
import Printutility from '@/utilities/PrintComponentMethod';
import LocalStorage from "@/utilities/localStorageUtil";
import programListStore from "@/store/modules/programList";
import { getSiteByProgramId } from "@/services/create-user/createUserService";
import SmartTableContainer from "@/commoncomponents/smartTableComponents/SmartTableContainer.vue";
import TeamRosterViewDetails from "@/popupcomponents/teamrosterviewdetails/TeamRosterViewDetails.vue";
import { TeamRosterPayload } from '@/Model/model';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
    components: {
        'event-item': EventItem,
        'task-item': TaskItem,
        'assign-site': AssignTask,
        'side-nav': SideNav,
        'planner-welcome': ProgramPlannerWelcome,
        'operate-task': OperateTask,
        'print-task': TaskPrintComponent,
        'operate-events': Events,
        'modal': modal,
        'calender-task-item': CalenderTaskItem,
        'popup-wrapper': PopupWrapperComponent,
        'calender-event-item': CalenderEventItem,
        'planner-print-component': PlannerPrintComponent,
        'bouncing-preloader': BouncingPreloaderComponent,
        'print-header': Header,
        'eventsPrint':EventsPrint,
        'smart-table':SmartTableContainer,
        'team-roster-view-flip': TeamRosterViewDetails
    }
})
export default class PlannerComponent extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

    private objScreenText: ScreenText = new ScreenText();
    private taskSortedArray: any = [];
    private tasksList: any = [];
    private eventsList: any = [];
    public openDropStatus: boolean = false;
    public showEventData: boolean = false;
    public checkAll: boolean = false;
    public selectedTasks: any = [];
    public sitesArray: any = [];
    public isCheckAll: boolean = false;
    public hoverStyleObj: any = {};
    public isCheckAllTasks: boolean = false;
    public assignTaskPayload = JSON.parse(JSON.stringify(APP_CONST.ASSIGN_TASKS));
    public roleId: number = 0;
    public userRoleId: number = 0
    public hovering: boolean = false;
    public listIndex: string | null = null;
    public toggleListView: boolean = true;
    public startDate: string = '';
    public endDate: string = '';
    public getTasksMenuList: any = JSON.parse(JSON.stringify(APP_CONST.GET_TASK_MENUS));
    public sortOrder: string = 'desc';
    public sortField: string = "";
    public assignTask: boolean = false;
    public sitesList: AddSiteDTO[] = [] as AddSiteDTO[];
    public viewEventId: number = 0;
    public pointerEventNone: boolean = false;

    public dropdownVisibility: boolean = false;
    public selectEntireTasks: boolean = false;
    public selectEntireSites: boolean = false;
    public taskDetails = JSON.parse(JSON.stringify(APP_CONST.PROGRAM_TASK));
    public ownersList: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }[] = [];
    public ownerId: number = 0;
    public programId: number = 0;
    public tabValue: string = 'myTasks'
    public siteId: number = 0;
    public selectedTasksStatus: any = { index: 0, label: 'ACTIVE', path: 'planner-status-profile.svg', value: '1' };
    public selectedSites: { siteId: number, startDate: string | Date, endDate: string | Date }[] = [] as { siteId: number, startDate: string | Date, endDate: string | Date }[];
    public togglePanelActive: boolean = false;
    public typeOfView: string = ""
    mode: string = 'add';
    public isMobileView: boolean = false;
    public activeStatusList: number[] = [1];
    public eventMode: string = 'add'
    public userRoleAccessToButton = APP_CONST.FEATURE_ACCESS_PERMISSION.plannerComponent.feature[0].role
    public userRoleAccess = APP_CONST.FEATURE_ACCESS_PERMISSION.plannerComponentAddEventButton.feature[0].role
    public isLoading: boolean = true;
    public isLeftPanelVisible: boolean = false;
    public editEventPayload: any = {}
    public editEventPayloadForPrint: any = {}
    lastSorting: any = { order: 0, sortField: "taskEndDate" };
    lastSortingIndex = 0
    newTaskListForSort: any = []
    public isEventLoading: boolean = true;
    public notifiedSiteList: { id: number, siteId: number, programId: number, isNotify: boolean, createdBy: number, createdAt: Date | string, modifiedAt: Date | string }[] = []
    selectedSiteName: string = "";
    showPrintComponent: boolean = false;
    printTaskList: any = [];
    printTaskDiscussionList: any = [];
    headerNotVisible: boolean = false;
    lastPageActive: boolean = true;
    isPdfPrintInProgress: boolean = false
    checkCalenderViewType: string = 'months'
    weekDate: string = '';
    calendarEventMode = 'View';
    apiResponseCode: any = 0
    private mountedref: boolean = false;
    private authKey: string = "";
    private order: number = 0;
    private isSingleEventDownloading: boolean = false
    private taskPrintList: any = []
    public eventFromHome: number = 0;
    year : number = 0;
    month : string = "";
    assignOnce=true;
    public siteDataResponse:any=''
    public siteValue:string=''
    public team_roster_header: any = APP_CONST.TEAM_ROSTER_HEADER
    public searchBox:string = ''
    public total_items:number = -1
    public page: number= 1;
    public isSearchRes:boolean= true;
    public isSearching:boolean= false;
    public teamrosterresponse:any='';
    public systemRoleSearch : any = {systemRole :  '' , inviteStatus : ''}
    public sortSearchLists: any = <any>[{ header: "systemRole", values: ['Program Admin', 'Session Admin', 'Staff', 'No Access']}, {header: "inviteStatus", values: ['--', 'Pending', 'Expired','Accepted', 'Deactivated']}];
    public isSortingSearching:boolean = true;
    public searchRoster : any = {
        firstName: "",
        lastName: "",
        systemRole: "",
        email:"",
        inviteStatus:"",
        sitesessions:"",
        phoneNumber:""
    }
    public sortFieldRoster:string="";
    public sortDirRoster: number = 0;
    public searchFieldRoster:string="";
    public searchValue:string = "";
    public isExactMatch: boolean = false;
    public teamRosterpayload = APP_CONST.TEAM_ROSTER_PAYLOAD;
    public siteSessionData:any = [];
    public renderTable: boolean = true;
    public disableDownloadButton:boolean = false;
    public currentPayload: TeamRosterPayload = APP_CONST.TEAM_ROSTER_PAYLOAD;
    public searchLoader:boolean = false
    public globalSearch:boolean=false;
    public localSearch:boolean=false;
    public teamRosterLabel:string = APP_CONST.TEAM_ROSTER_LABEL;
    public displayALL: boolean = true
    private isMobileForAPiCall:boolean = false;
    public movableTableHeaders = APP_CONST.TEAM_ROSTER_MOBILE_HEADER.MOVABLE;
    private sortObjPersist: any = {};
    private searchObjPersist: any = {};
    private localSearchData:any = {};
    public localConst:any = APP_CONST;
    public isLocalSearch:boolean = false;
    public isGlobalSearch:boolean = false;
    public isDownloadEventListViewInProgress:boolean = false;

    @Prop()
    userDetails!: any;

    @Prop()
   noOfRowsForMobile!:10

    get getteamRosterListData(){
        return  plannerStore.getteamRosterList;
    }
    get teamRosterCompletedataa(){
        return plannerStore.getteamRosterCompletedata;
    }

    get teamrosterDataCount(){
        return plannerStore.getteamrosterdatacount;
    }

    get getRosterPayload(){
      return plannerStore.getRosterPayloadStore;
    }
    
    get teamRosterResizeArray() {
        return plannerStore.teamRosterResizeArray;
    }
    
    get displayColumns(){
        return plannerStore.teamRosterFilteredObject
    }

    get allTeamRostersSortObj(){
        return plannerStore.allTeamRosterSortObj;
    }

    get initialTeamRosterCount(){
        return plannerStore.initialTeamRecords
    }

    get isLoaderVisible(){
        return (this.initialTeamRosterCount>=this.localConst.HUNDRED || this.initialTeamRosterCount <= this.localConst.ZERO)
    }

  @Watch('userDetails', { deep: true, immediate: true })
  getSiteList(data: any) {
  }

    @Watch('activeTab', { deep: true, immediate: true })
    tabUpdate(tab: any) {
        this.selectedTasks = [];
        this.selectEntireTasks = false;
    }

    @Watch('tasksList', { deep: true, immediate: true })
    tasksUpdate(val: any) {
        let sideNavRef = this.$refs.sideNavPanel as any;
        if (sideNavRef) {
            sideNavRef.hide();
        }
        this.isLeftPanelVisible = false;
    }

    @Watch('siteId', { deep: true, immediate: true })
    siteIdUpdate(siteId: any) {
        if (!siteId) {
            let { programId } = APP_UTILITIES.coreids();
            this.configureProgram(programId);
        } else {
            let selectedSite: any = this.sites.length ? this.sites.find((site: any) => site.siteId == siteId) : [];
            if (selectedSite.length) {
                this.setSiteDate(selectedSite)
            } else {
                selectedSite = this.userDetails.sitePanels.find((site: any) => site.siteId == siteId)
                this.setSiteDate(selectedSite)
            }
        }
        plannerStore.updateSiteId(siteId);
    }
    setSiteDate(selectedSite: any) {
        /* istanbul ignore else */
        if (selectedSite !== undefined) {
            this.selectedSiteName = selectedSite.siteName;
            let site: any = APP_UTILITIES.getCookie('siteName');
            this.selectedSiteName = site || this.selectedSiteName;
            this.startDate = typeof selectedSite.startDate != "undefined" || null || "" ? APP_UTILITIES.formatDate(selectedSite.startDate) : "";
            this.endDate = typeof selectedSite.endDate != "undefined" || null || "" ? APP_UTILITIES.formatDate(selectedSite.endDate) : "";
        }
    }

    get tasksListUpdate() {
        let { id } = APP_UTILITIES.coreids();
        let action: any = this.$refs.taskListItem;
        if (action) {
            action.showOwnerDropdown = false;
        }
        if ((this.activeTab == "allTasks" || this.activeTab == "myTasks") && this.userRoleId == APP_CONST.PROGRAM_ADMIN_ROLE_ID) {
            this.siteId = 0;
        }
        if (this.activeStatusList.length > 0 && this.activeTab == "myTasks") {
            let tasksList: any = this.tasks.filter((item: any) => this.activeStatusList.includes(item.status));
            let taskListArray: any = [];
            /* istanbul ignore else */
            if (tasksList) {
                this.tasksList = tasksList.forEach((taskItem: any) => {
                    if (taskItem.taskUsers) {
                        let userIdArray: number[] = [];
                        let taskIdArray: number[] = [];
                        taskItem.taskUsers.forEach((task: any) => {
                            if (task.userId == id && !userIdArray.includes(task.userId) && !taskIdArray.includes(task.taskId)) {
                                userIdArray.push(task.userId);
                                taskListArray.push(taskItem);
                                taskIdArray.push(task.taskId);
                            }
                        });
                    }
                });
            }
            this.tasksList = taskListArray;
        } else if (this.activeStatusList.length < 1 && this.activeTab == "myTasks") {
            let taskListArray: any = [];
            if (this.tasks) {
                this.tasksList = this.tasks.forEach((taskItem: any) => {
                    if (taskItem.taskUsers) {
                        let userIdArray: number[] = [];
                        let taskIdArray: number[] = [];
                        taskItem.taskUsers.forEach((task: any) => {
                            if (task.userId == id && !userIdArray.includes(task.userId) && !taskIdArray.includes(task.taskId)) {
                                taskListArray.push(taskItem);
                                userIdArray.push(task.userId);
                                taskIdArray.push(task.taskId);
                            }
                        });
                    }
                });
            }
            this.tasksList = taskListArray;
        } else if (this.activeStatusList.length > 0 && this.activeTab != "myTasks") {
            let val = [];
            if (this.activeTab == "allTasks") {
                val = this.siteId ? this.tasks.filter((item: any) => this.siteId == item.siteId) : this.tasks;
            } else {
                val = this.siteId && this.selectedSiteName ? this.tasks.filter((item: any) => this.siteId == item.siteId) : this.tasks;
            }
            this.tasksList = val.filter((item: any) => this.activeStatusList.includes(item.status));
        } else if (this.activeStatusList.length < 1 && this.activeTab != "myTasks") {
            let val = [];
            if (this.activeTab == "allTasks") {
                val = this.siteId ? this.tasks.filter((item: any) => this.siteId == item.siteId) : this.tasks;
            } else {
                val = this.siteId && this.selectedSiteName ? this.tasks.filter((item: any) => this.siteId == item.siteId) : this.tasks;
            }

            this.tasksList = val;
        }
        this.printTaskList = this.tasksList;
        if (this.mountedref) {
            this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
            if (this.authKey && this.printTaskList.length) {
                this.getSortingDefault();
            }
        }

        if (this.activeTab == "allTasks" && this.userRoleId == APP_CONST.PROGRAM_ADMIN_ROLE_ID) {
            let { programId } = APP_UTILITIES.coreids();
            this.tasksList = this.tasksList.filter((tasks: any) => tasks.programId == programId);
            return this.tasksList.filter((a: any) => a.siteId == 0);
        }
        else {
            return this.tasksList;
        }
    }

    @Watch('sites', { deep: true, immediate: true })
    siteDataUpdate(val: any) {
        let sitesFill: any = [];
        /* istanbul ignore else */
        if (val) {
            val.forEach((item: any) => {
                sitesFill.push({ value: item.siteName, siteId: item.siteId, startDate: item.startDate, endDate: item.endDate, checked: false });
            })
            sitesFill.sort(this.sortByProperty("value", 'asc'));
            this.sitesList = sitesFill;
        }
    }

    @Watch('events', { deep: true, immediate: true })
    eventsListUpdate(val: any) {
        /* istanbul ignore else */
        if (val) {
            for (let i = 0; i < val.length; i++) {
                val[i].event_date = APP_UTILITIES.dayFormat(val[i].startDate);
                val[i].event_month = APP_UTILITIES.monthFormat(val[i].startDate);
                val[i].event_start_time = val[i].isEvent ? APP_UTILITIES.formatAMPM(val[i].startDate) : '';
                val[i].event_end_time = val[i].isEvent ? APP_UTILITIES.formatAMPM(val[i].endDate) : '';
                val[i].event_start_timestamp = new Date(val[i].startDate).getTime();
                val[i].event_end_timestamp = new Date(val[i].endDate).getTime();
                val[i].event_start_time_24 = APP_UTILITIES.convertTime12to24(val[i].event_start_time);
                val[i].event_duration = (val[i].event_end_timestamp - val[i].event_start_timestamp) / 60000;
            }
        }
        this.eventsList = val;
        /* istanbul ignore else */
        if (this.eventFromHome) {
            this.viewCondensePlannerTask();
        }
    }

    @Watch('tasksListUpdate', { deep: true, immediate: true })
    checkBoxHandler(val: any) {
        let checkedTasks = [];
        val.forEach((task: any) => {
            if (task.checked) {
                checkedTasks.push(task);
            }
        });
        this.selectEntireTasks = (val.length == checkedTasks.length) ? true : false;
    }

    @Watch('activeTab', { immediate: true })
    activeTabWatchHandler(newValue: string) {
      if (newValue === 'myTasks') {
        this.analyticsService.track(analyticsEventNames.PROGRAM_PLANNER_MY_TASKS_PAGE_VIEWED);
      }
      else if (newValue === 'events') {
        this.analyticsService.track(analyticsEventNames.PROGRAM_PLANNER_EVENTS_PAGE_VIEWED); 
      }
      else if (newValue === 'teamRoster') {
        this.analyticsService.track(analyticsEventNames.PROGRAM_PLANNER_TEAM_ROSTER_VIEWED); 
      }
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    get activeTab() {
        return plannerStore.getActiveTab;
    }

    get tasks() {
        return plannerStore.tasksList;
    }

    get events() {
        return plannerStore.eventsList;
    }

    get sites() {
        return plannerStore.sitesList;
    }

    get teamRosterResponseStatusCode(){
        return plannerStore.getTeamRosterResponseStatusCode;
    }

    get selectedSite() {
        return plannerStore.getSiteId;
    }

    showDropdown() {
        this.dropdownVisibility = !this.dropdownVisibility;
        this.isCheckAll = false;
        this.selectEntireSites = false;
        plannerStore.siteSelection({ siteList: this.sitesList, selectAll: false });
    }

    initializeTeamRoster(){
        plannerStore.initlializeTeamRosterResponseStatusCode();
        this.searchBox = '';
    }

    filterTaskList(statusArr: number[]) {
        this.activeStatusList = statusArr;
        plannerStore.updateStatusList(statusArr);
        let taskListArray: any = [];
        if (this.activeTab == "myTasks" && statusArr.length > 0) {
            this.tasksList = this.tasks.filter((item: any) => statusArr.includes(item.status));
            this.tasksList = this.tasksList.forEach((taskItem: any) => {
                if (taskItem.taskUsers) {
                    let userIdArray: number[] = [];
                    let taskIdArray: number[] = [];
                    taskItem.taskUsers.forEach((task: any) => {
                        if (task.userId == this.ownerId && !userIdArray.includes(task.userId) && !taskIdArray.includes(task.taskId)) {
                            userIdArray.push(task.userId);
                            taskListArray.push(taskItem);
                            taskIdArray.push(task.taskId);
                        }
                    });
                }
            });
            this.tasksList = taskListArray;

        } else if (this.activeTab == "myTasks" && statusArr.length < 1) {
            this.tasksList = this.tasks.filter((item: any) => {
                if (item.taskUsers) {
                    let userIdArray: number[] = [];
                    let taskIdArray: number[] = [];
                    item.taskUsers.forEach((task: any) => {
                        if (task.userId == this.ownerId && !userIdArray.includes(task.userId) && !taskIdArray.includes(task.taskId)) {
                            userIdArray.push(task.userId);
                            taskListArray.push(item);
                            taskIdArray.push(task.taskId);
                        }
                    });
                }
            });
            this.tasksList = taskListArray;
        } else if (this.activeTab == "allTasks" && statusArr.length < 1) {
            this.tasksList = this.tasks;
        } else if (this.activeTab == "allTasks" && statusArr.length > 0) {
            this.tasksList = this.tasks.filter((item: any) => statusArr.includes(item.status));
        } else if ((this.activeTab != "allTasks" && this.activeTab != "myTasks") && statusArr.length > 0) {
            this.tasksList = this.tasks.filter((item: any) => statusArr.includes(item.status));
        } else if ((this.activeTab != "allTasks" && this.activeTab != "myTasks") && statusArr.length < 1) {
            this.tasksList = this.tasks;
        }

        this.updateSortTaskList();
    }

    HideSiteDropDownAndSelect() {
        if (this.userRoleId == APP_CONST.PROGRAM_ADMIN_ROLE_ID) {
            this.dropdownVisibility = false;
            this.checkAll = false
        }
    }

    filterTasksByTab(tab: string) {
        if(tab=='teamRoster'){
            plannerStore.getPersistenceData()
        }
        if(this.activeTab === 'events' || tab === 'events'){
            this.checkCalenderViewType = 'months';
        }
        plannerStore.updateActiveTab(tab);
        plannerStore.taskSelection({ taskList: this.tasks, selectAll: false });
        this.showEventData = false;
        if (tab != 'events') {
            let site: any = APP_UTILITIES.getCookie('siteName');
            this.selectedSiteName = site ? site : "";
        }
        if (tab && tab == 'myTasks') {
            this.siteId = this.assignTask ? 0 : this.siteId;
            this.getTask(this.programId, this.siteId ? this.siteId : 0);
            let tasksList: any = plannerStore.tasksList || [];
            if (this.activeStatusList.length < 1) {
                this.tasksList = tasksList.filter((item: any) => {
                    if (item.taskUsers) {
                        this.tasksList = item.taskUsers.forEach((task: any) => task.userId == this.ownerId);
                    }
                });
            } else {
                tasksList = this.tasks.filter((item: any) => this.activeStatusList.includes(item.status));
                if (tasksList.taskUsers) {
                    this.tasksList = tasksList.taskUsers.forEach((task: any) => task.userId == this.ownerId);
                } else {
                    this.tasksList = [];
                }
            }
        } else if (tab && tab == 'allTasks') {
            const { siteId } = APP_UTILITIES.coreids();
            this.siteId = (!siteId) ? 0 : this.siteId;
            this.getTask(this.programId, this.siteId ? this.siteId : 0);
            this.tasksList = this.activeStatusList.length < 1 ? this.tasks : this.tasks.filter((item: any) => this.activeStatusList.includes(item.status));
        } else if (tab && tab == 'events') {
            this.getEventsData();
        }
      
    }

    getEventsData() {
        this.dropdownVisibility = false;
        this.showEventData = true;
        this.eventsList = plannerStore.eventsList || [];
        if (this.eventsList.length == 0) {
            this.getEvents(this.programId, this.siteId ? this.siteId : 0);
        }
    }


    filterTasksBySite(siteId: number) {
        plannerStore.updateActiveTab("");
        plannerStore.updateSiteId(siteId);
        this.siteId = siteId;
        if (this.assignTask) {
            let selectedSite: any = this.sites.find((site: any) => site.siteId == siteId);
            if (selectedSite) {
                this.selectedSiteName = selectedSite.siteName;
                this.startDate = typeof selectedSite.startDate != "undefined" || null || "" ? APP_UTILITIES.formatDate(selectedSite.startDate) : "";
                this.endDate = typeof selectedSite.endDate != "undefined" || null || "" ? APP_UTILITIES.formatDate(selectedSite.endDate) : "";
            }
        }
        this.getTask(this.programId, siteId);
        this.tasksList = this.activeStatusList.length < 1 ? this.tasks : this.tasks.filter((item: any) => this.activeStatusList.includes(item.status));
    }

    handleToggleLeftPanel(togglePanel: boolean) {
        this.togglePanelActive = togglePanel;
    }

    isMobile() {
        this.isMobileView = APP_UTILITIES.isMobile();
    }

    showSidePanel() {
        this.isLeftPanelVisible = true;
    }

    skipTasks() {
        let { programId } = APP_UTILITIES.coreids();
        let payload: any = {
            status: this.getScreenText('SKIP_STATUS'),
            taskIds: this.selectedTasks
        };
        this.selectedTasks = [];
        plannerStore.skipTasks(payload).then((data: any) => {
            this.selectEntireTasks = false
            this.getTask(programId, this.siteId ? this.siteId : 0);
        });
    }

    beforeDestroy() {
        if (UIkit.modal(`#operate-task`)) {
            UIkit.modal(`#operate-task`).$destroy(true);
        };
        Vue.prototype.$eventHub.$off('close-mobile-side-panel');
        if (UIkit.offcanvas('#eventsWrap')) {
            UIkit.offcanvas('#eventsWrap').$destroy(true);
        }
        plannerStore.updateSidePanelActivities({
            activity: "listViewType",
            value: 'list'
        });
        plannerStore.updateSidePanelActivities({
            activity: "isListViewActive",
            value: true
        });
    }

    editedCompareValue(view: string) {
        this.typeOfView = view
    }

    toggleListByView(isListViewActive: boolean) {
        plannerStore.taskSelection({ taskList: this.tasks, selectAll: false });
        this.selectEntireTasks = false;
        this.selectedTasks = [];
        this.toggleListView = isListViewActive;
    }

    spliteArray(array: any, size: number) {
        var results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;

    }

    async downloadTask() {

        this.isPdfPrintInProgress = true;
        let breakHeight = 1500;
        let scale = 1;
        let threshold = 99
        let width = this.isMobileView ? 1144 : 0

        let dateObj = new Date();
        let currentDate = APP_UTILITIES.getSlashedDates(dateObj);
        currentDate = currentDate.replace(/-/g, "_");
        await Printutility.getPrintView('printTaskComponent', breakHeight, width, scale, this.taskDetails.name, "", threshold, true, false)

        this.isPdfPrintInProgress = false

    }

    async downloadTasks() {
        if (this.activeTab != 'events') {
            this.isPdfPrintInProgress = true;
            if (!this.toggleListView) {
                let breakHieght = 1100;
                let scale = 1.7;
                let threshold = 99
                let width = 1144
                let dateObj = new Date();
                if (!this.month && this.year === 0) {
                    this.month = APP_UTILITIES.fullMonthFormat(dateObj);
                    this.year = dateObj.getFullYear();
                }
                let date = dateObj.getDate();
                let currentDate = this.month + ' ' + this.year;
                let fileName = this.checkCalenderViewType == 'months' ? "Task Calendar_" + currentDate : "Task Calendar_Week of " + this.weekDate;
                await Printutility.getPrintView('calendar-view', breakHieght * scale, width, scale, fileName, "HeaderCalenderEvent", threshold, this.toggleListView, false)
                this.isPdfPrintInProgress = false
            } else {
                let taskItem = JSON.parse(JSON.stringify(this.tasksList))
                let splitArray = this.spliteArray(taskItem, 10);
                let el = document.getElementById('plannerPrintComponent') as HTMLElement;
                let base64Arr: any = [];
                for (let i = 0; i < splitArray.length; i++) {
                    this.taskPrintList = splitArray[i];

                    await html2canvas(el, {
                        useCORS: true,
                        height: 1150,
                        onclone: (clonedDoc: any) => {
                            clonedDoc.getElementById('plannerPrintComponent').style.width = '1144px';
                        }
                    }).then((canvas: any) => {
                        let finalingData = canvas.toDataURL("image/png", 1.0);

                        base64Arr.push(finalingData);
                    }).catch((error: any) => {
                        console.log("error", error)
                    });

                }
                if (base64Arr.length) {
                    let dataToSend: any = {
                        base64Data: base64Arr,
                        landscape: false,
                        outputFilename: 'plannerPrint'
                    }
                    printHTMLTOPdf(dataToSend).then((res: any) => {
                        if (res.status === APP_CONST.RESPONSE_200) {
                            var bufferArray = this.base64ToArrayBuffer(res.data);
                            var blobStore = new Blob([bufferArray], { type: "application/pdf" });
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blobStore);
                                return;
                            }
                            this.taskPrintList = []
                            var data = window.URL.createObjectURL(blobStore);
                            var link = document.createElement('a');
                            document.body.appendChild(link);
                            link.href = data;
                            let dateObj = new Date();
                            let currentDate = APP_UTILITIES.getSlashedDates(dateObj);
                            currentDate = currentDate.replace(/-/g, "_");
                            link.download = "Task List_" + currentDate;
                            link.target = "_blank";
                            link.click();
                            window.URL.revokeObjectURL(data);
                            link.remove();
                        }
                        this.isPdfPrintInProgress = false;
                    }).catch((error: any) => {
                        this.isPdfPrintInProgress = false;
                    })
                }
            }
        } else {
            this.makePDF()
        }
    }

    base64ToArrayBuffer(data: any) {
        var bString = window.atob(data);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    mounted() {
        this.isGlobalSearch = false;
        let teamRosterSearchData:any = this.persistSearching() ? this.persistSearching() : {};
        if(teamRosterSearchData && teamRosterSearchData.searchField && teamRosterSearchData.searchValues){
            this.searchRoster[teamRosterSearchData.searchField] = teamRosterSearchData.searchValues;
            this.localSearch = true;
            this.localSearchData = teamRosterSearchData;
            this.searchFieldRoster = teamRosterSearchData.searchField;
            this.searchValue = teamRosterSearchData.searchValues;
            this.isExactMatch = teamRosterSearchData.isExactMatch;
        }
        let teamRosterSortData:any = this.persistSorting() ? this.persistSorting() : {};
        if(teamRosterSortData && teamRosterSortData.sortField && teamRosterSortData.sortDir){
            this.sortFieldRoster = teamRosterSortData.sortField;
            this.sortDirRoster = teamRosterSortData.sortDir;
        }
        plannerStore.teamRosterListmut([])
        this.isMobileView = APP_UTILITIES.isMobile();
        window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));

        let { id, accountId, programId, roleId, siteId } = APP_UTILITIES.coreids();
        this.userRoleId = roleId
        let userId: any = APP_UTILITIES.getCookie("user_id");
        let super_user_drilldown = APP_UTILITIES.getCookie("super_user_drilldown")
        let account_admin_drilldown = APP_UTILITIES.getCookie("account_admin_drilldown");
        let drilldown_key = APP_UTILITIES.getCookie("drilldown_key")
        this.ownerId = parseInt(userId);
        this.programId = programId;
        this.siteId = siteId;
        (super_user_drilldown === 'true' || (account_admin_drilldown != null && account_admin_drilldown != "") || (drilldown_key != null && drilldown_key != "")) ? '' : this.checkForUserState({ userId: id, programId: programId })
        this.assignTask = roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID ? true : false;
        let plannerTabActive = localStorage.getItem('planner_tab_active') == 'events' ? true : false;
        if (this.$route.params.id !== ("0" && undefined)) {
            this.eventFromHome = +this.$route.params.id;
            plannerTabActive = this.$route.params.type === "event" ? true : false;
        }
        this.setPersistence(programId, plannerTabActive);
        plannerStore.updateSidePanelActivities({
            activity: "isProgramAdmin",
            value: roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID ? true : false
        });
        this.getUsers(accountId, programId);
        this.printTaskDiscussionList = this.taskDetails.taskDiscussions;
        let site: any = APP_UTILITIES.getCookie('siteName');
        this.selectedSiteName = site || "";

        if (this.mountedref == false) {
            this.mountedref = true;
        }
        this.getSite(programId);
    }

    getSite(programId: number) {
        getSiteByProgramId(programId).then((res: any) => {
            if (res.status === APP_CONST.RESPONSE_200) {
                res.data.forEach((sites:any) => {
                    sites[APP_CONST.IS_CHECKED] = false;  
                });
                this.siteDataResponse = res.data
                plannerStore.updateSitesData(res.data);
            }
        })
    }

    setPersistence(programId: number, localStorageActiveTab: boolean) {
        if (localStorageActiveTab) {
            plannerStore.updateActiveTab('events');
            plannerStore.updateSidePanelActivities({
                activity: "eventTabUpdateStatus",
                value: true
            });
            this.getEventsData();
        } else {
            this.defaultTaskDecider();
            this.prepareAddTaskModel();
            this.getTasksList(programId);
            this.configureProgram(programId);
        }
    }
    configureProgram(programId: number) {
        programConfigureStore.getProgramConfigObj(programId).then((programData: any) => {
            if (programData && programData.data && !this.siteId) {
                this.startDate = APP_UTILITIES.formatDate(programData.data.startDate);
                this.endDate = APP_UTILITIES.formatDate(programData.data.endDate);
            }
        })
    }

    getTasksList(programId: number) {
        this.getTask(programId, this.siteId ? this.siteId : 0);
    }

    getTask(programId: number, siteId?: number) {
        let payload: any = { programId: programId, productId: programConfigureStore.productId };
        if (siteId) {
            payload['siteId'] = siteId;
        }
        this.isLoading = true;
        plannerStore.tasks(payload).then((response: any) => {
            this.isLoading = false;
            this.viewCondensePlannerTask();
        })
            .catch(() => {
                this.isLoading = false;
            });
    }

    defaultTaskDecider() {
        if (this.$route.params.id !== ("0" && undefined) && this.$route.params.type === "task") {
            let super_user_drilldown = APP_UTILITIES.getCookie("super_user_drilldown")
            let account_admin_drilldown = APP_UTILITIES.getCookie("account_admin_drilldown");
            let drilldown_key = APP_UTILITIES.getCookie("drilldown_key")
            if ((super_user_drilldown === 'true' || (account_admin_drilldown != null && account_admin_drilldown != "") || (drilldown_key != null && drilldown_key != ""))) {
                plannerStore.updateActiveTab('allTasks');
            } else {
                plannerStore.updateActiveTab('myTasks');
            }
        } else {
            let event = localStorage.getItem('planner_tab_active');
            if(event && event == this.teamRosterLabel){
                plannerStore.updateActiveTab(this.teamRosterLabel);
            }else{
                plannerStore.updateActiveTab('myTasks');
            }
        }
    }

    viewCondensePlannerTask() {
        const id = +this.$route.params.id || 0;
        const type = this.$route.params.type || "";
        if (id && (type === "task")) {
            this.viewTask({ taskId: id });
        } else if (id && type === "event") {
            let event = this.eventsList.filter((event: any) => event.id == this.eventFromHome);
            setTimeout(() => {
                this.detailsEvent(event[0]);
            }, 200);
            this.eventFromHome = APP_CONST.ZERO;
        }
        this.$route.params.id = "0";
        this.$route.params.type = "";
    }

    updateSiteNotification(siteId: number) {
        let payload: any = { programId: this.programId, productId: programConfigureStore.productId };
        /*instanbul ignore else*/
        if (siteId) {
            payload['siteId'] = siteId;
        }
        updateSiteNotificationInfo(payload);
    }

    callHover(title: string, $event: any) {
        this.listIndex = title;
        this.hovering = true;
        const boundBox = $event && $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            top: (coordY + 25).toString() + "px",
            left: (coordX + 10).toString() + "px"
        }
    }
    callHoverOut() {
        this.listIndex = null;
        this.hovering = false
    }

    postTasks() {
        if (!this.assignOnce) {
            return
        }
        this.assignOnce = false;
        this.assignTaskPayload.taskIds = this.selectedTasks;
        this.assignTaskPayload.sites = this.selectedSites;
        this.openDropStatus = !this.openDropStatus ? APP_CONST.TRUE : APP_CONST.FALSE;
        let { programId } = APP_UTILITIES.coreids();
        this.assignTaskPayload.programId = programId;
        plannerStore.assignTasks(this.assignTaskPayload).then((data: any) => {
            this.dropdownVisibility = false;
            this.assignOnce = true;
            this.selectedTasks = [];
            this.selectedSites = [];
            plannerStore.siteSelection({ siteList: this.sitesList, selectAll: false });
            this.getTask(programId, this.siteId ? this.siteId : 0);
            this.getNotifiedSites();
        })
    }
    getNotifiedSites() {
        let { programId } = APP_UTILITIES.coreids();
        plannerStore.getNotifiedSites(programId).then((response: any) => {
            this.notifiedSiteList = response.data;
        })
    }

    checkForUserState(payload: { userId: number, programId: number }) {
        /* istanbul ignore else */
        if (this.$route.params.id !== ("0" && undefined)) {
            return;
        }
        programPlannerStore.getUserInformation(payload).then((data: any) => {
            /* istanbul ignore else */
            if (data.data == false) {
                /* istanbul ignore else */
                if (UIkit.modal('#program-planner-welcome')) {
                    UIkit.modal('#program-planner-welcome').show();
                }
            }
        })
    }

    closeWelcomePopup(ev: { id: string }) {
        /* istanbul ignore else */
        if (UIkit.modal(`#${ev.id}`)) {
            UIkit.modal(`#${ev.id}`).hide();
        }
        let { id, programId } = APP_UTILITIES.coreids();
        programPlannerStore.postUserInformation({ userId: id, programId: programId });
    }

    addRemoveTasks(task: { id: number, checked: boolean }) {
        const index = this.selectedTasks.indexOf(task.id);
        const tasks = this.tasks;
        if (index >= 0 && task.checked == false) {
            setTimeout(() => {
                this.selectedTasks.splice(index, 1);
                plannerStore.taskSelection({ taskList: tasks, selectAll: null, task });
                this.dropdownVisibility = this.selectedTasks.length <= 0 ? false : this.dropdownVisibility;
                this.selectEntireTasks = false
                this.isCheckAll = false
            }, 100);
        } else if (index < 0 && task.checked == true) {
            setTimeout(() => {
                this.selectedTasks.push(task.id);
                let checkedTasks = [];
                this.tasksListUpdate.forEach((taskItem: any) => {
                    if (taskItem.checked) {
                        checkedTasks.push(taskItem);
                    }
                });
                this.selectEntireTasks = (this.tasksListUpdate.length == checkedTasks.length) ? true : false;
                plannerStore.taskSelection({ taskList: tasks, selectAll: null, task });
            }, 100);
        }
        this.selectEntireTasks = this.selectedTasks.length === this.tasks.length ? true : false;
        this.isCheckAll = this.selectedTasks.length <= 0 ? false : this.isCheckAll;
    }

    addRemoveSelectedSites(site: { siteId: number, checked: boolean, startDate: string, endDate: string }) {
        const index = this.selectedSites.findIndex((el) => el.siteId === site.siteId);
        const sites = this.sitesList;
        if (index >= 0 && site.checked == false) {
            this.selectedSites.splice(index, 1);
            plannerStore.siteSelection({ siteList: sites, selectAll: null, site });
        } else if (index < 0 && site.checked == true) {
            let siteInfo = { siteId: site.siteId, startDate: site.startDate, endDate: site.endDate }
            this.selectedSites.push(siteInfo);
            plannerStore.siteSelection({ siteList: sites, selectAll: null, site });
        }
        this.selectEntireSites = this.selectedSites.length === this.sitesList.length ? true : false;
        this.isCheckAll = this.selectedSites.length <= 0 ? false : this.isCheckAll;
    }

    selectAllSites(checked: boolean) {
        const sites = this.sitesList
        if (!checked) {
            plannerStore.siteSelection({ siteList: sites, selectAll: true });
            for (let site of this.sitesList) {
                let siteInfo = { siteId: site.siteId || 0, startDate: site.startDate || '', endDate: site.endDate || '' }
                this.selectedSites.push(siteInfo);
            }
        } else {
            this.selectedSites = [];
            this.isCheckAll = false;
            plannerStore.siteSelection({ siteList: sites, selectAll: false });
        }
        this.selectEntireSites = this.selectedSites.length === this.sitesList.length ? true : false;
    }

    selectAllTasks(ev: any) {
        const tasks = this.tasks;
        if (ev.target.checked) {
            plannerStore.taskSelection({ taskList: tasks, selectAll: true });
            this.selectedTasks = []
            for (let task of this.tasksListUpdate) {
                this.selectedTasks.push(task.id);
            }
        } else {
            this.selectedTasks = [];
            this.dropdownVisibility = false;
            this.isCheckAll = false;
            plannerStore.taskSelection({ taskList: tasks, selectAll: false });
        }
    }

    formatDate(date: string) {
        return date ? APP_UTILITIES.formatDate(date, false, true) : '';
    }

    close(ev: { name: string }) {
        if (UIkit.modal(`#${ev.name}`)) {
            UIkit.modal(`#${ev.name}`).hide();

            setTimeout(() => {
                const htmlElem = <any>document.getElementsByTagName('html')[0];
                htmlElem.classList.remove('overflow-page');
                const el = document.getElementById('operate-task');
                el && (el.scrollTop = 0);

                const elBody = document.getElementById('body');
                elBody && (elBody.scrollTop = 0);

                const elRightPanel = document.getElementById('right-panel');
                elRightPanel && (elRightPanel.scrollTop = 0);
            }, 200);
            this.taskDetails = JSON.parse(JSON.stringify(APP_CONST.PROGRAM_TASK));
            this.mode = 'add';
            this.prepareAddTaskModel();
            this.selectEntireTasks = false;
        }
    }
    getUsers(accountId: number, programId: number) {
        getOwnersByAccountId(accountId, programId).then((res: any) => {
            if (res.status === APP_CONST.RESPONSE_200) {
                let result: any = [];
                res.data.forEach((item: any) => {
                    result.push({ firstName: item.firstName, lastName: item.lastName, id: item.id, profilePicture: item.profilePicture, email: item.email });
                })
                result.sort(function (a: any, b: any) {
                    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
                    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
                    return 0;
                })
                this.ownersList = result;
            }
        })
    }
    putNewOwner(ownerInfo: { newOwnerId: number, taskId: number }) {
        const payload = { id: ownerInfo.taskId, ownerId: ownerInfo.newOwnerId, previousOwnerId: this.ownerId, siteId: this.siteId || 0, status: 0, programId: this.programId || 0 }
        putNewOwner(payload).then(() => {
            this.getTask(this.programId, this.siteId ? this.siteId : 0);
            this.updateSortTaskList()
        })
    }

    updateTaskStatus(taskStatus: { status: number, taskId: number }) {
        this.selectedTasks = [];
        const payload = { status: taskStatus.status, id: taskStatus.taskId }
        updateTaskStatus(payload).then(() => {
            this.getTask(this.programId, this.siteId ? this.siteId : 0);
        })
    }

    prepareAddTaskModel() {
        const { programId } = APP_UTILITIES.coreids();
        this.taskDetails.programId = programId;
    }

    fetchRequiredUserList() {
        const usersList = this.ownersList.length ? this.ownersList : [];
        const taskUsers = JSON.parse(JSON.stringify(this.taskDetails.taskUsers));
        taskUsers.forEach((user: { id: number, taskId: number, userId: number, isOwner: boolean, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }) => {
            const index = usersList.findIndex((userDt: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }) => userDt.id === user.userId);
            if (index >= 0) {
                user.profilePicture = usersList[index].profilePicture;
                user.firstName = usersList[index].firstName;
                user.lastName = usersList[index].lastName;
                user.email = usersList[index].email;
            }
            this.taskDetails.taskUsers = JSON.parse(JSON.stringify(taskUsers));
        })
    }

    openAddTaskForm(mode: string) {
        this.prepareAddTaskModel();
        this.openForm(mode);
    }

    openTaskForm(mode: string) {
        /*instanbul ignore else*/
        if (UIkit.modal(`#operate-task`)) {
            this.mode = mode;
            UIkit.modal(`#operate-task`).show().then(() => {
                setTimeout(() => {
                    const htmlElem = <any>document.getElementsByTagName('html')[0];
                    htmlElem.classList.add('overflow-page');
                }, 100)
            });
        }
    }

    openForm(mode: string) {
        if (this.activeTab !== "events") {
            this.fetchRequiredUserList();
            this.openTaskForm(mode);
        } else {
            this.pointerEventNone = true
            this.$emit(APP_CONST.NO_ACTION, this.pointerEventNone)
            /*instanbul ignore else*/
            if (UIkit.offcanvas('#eventsWrap')) {
                this.eventMode = mode
                UIkit.offcanvas('#eventsWrap').show();
            }
        }

    }

    noActionPointer(pointer: boolean) {
        this.$emit(APP_CONST.NO_ACTION, pointer)
    }

    addEventData(payloadJson: any) {
        let eventPayload = JSON.parse(JSON.stringify(payloadJson));
        eventPayload.endTime = eventPayload.endTime ? eventPayload.endTime : '00:00';
        eventPayload.startTime = eventPayload.startTime ? eventPayload.startTime : '00:00';
        let createPayloadForView = {
            endDate: payloadJson.isEvent ? eventPayload.date + ' ' + eventPayload.endTime : eventPayload.endDate + ' ' + eventPayload.endTime,
            event_date: APP_UTILITIES.dayFormat(eventPayload.date),
            event_end_time: eventPayload.endTime,
            event_month: APP_UTILITIES.monthFormat(eventPayload.date),
            event_start_time: eventPayload.startTime,
            id: eventPayload.id,
            isEvent: payloadJson.isEvent,
            isEdit: payloadJson.isEdit,
            name: eventPayload.name,
            programId: eventPayload.programId,
            siteId: eventPayload.siteId,
            startDate: payloadJson.isEvent ? eventPayload.date + ' ' + eventPayload.startTime : eventPayload.startDate + ' ' + eventPayload.startTime,
            location: eventPayload.location,
            notes: eventPayload.notes,
            documentDtos: payloadJson.documentDtos,
            status: 1,
            siteIds: eventPayload.siteIds
        }
        if (eventPayload.state == 'deleteEvent') {
            let deletedEventIndex = this.eventsList.findIndex((item: any) => item.id == payloadJson.id);
            this.eventsList.splice(deletedEventIndex, 1);
            this.isEventLoading = false;
        }
        else if (eventPayload.state == 'updateEvent') {
            this.updateEventStore({ ...createPayloadForView })
            this.isEventLoading = false;
        } else {
            this.updateLocalStore(createPayloadForView)
        }


        setTimeout(() => {
            let evntInstance: any = this.$refs.eventListItem;
            if (evntInstance) {
                evntInstance.sortEventAfterCreate("addUpdateDelete");
            }

        }, 10)
    }

    updateLocalStore(createPayloadForView: any) {
        plannerStore.updateEventList(createPayloadForView);
    }
    updateEventStore(createPayloadForView: any) {
        plannerStore.updateEventListStoreData(createPayloadForView);
    }
    changeMode(ev: { mode: string }) {
        this.mode = ev.mode;
    }

    viewTask(id: { taskId: number }) {
        getTaskDetails(id.taskId).then((response: any) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                this.taskDetails.taskUsers = [];
                this.taskDetails = Object.assign(this.taskDetails, response.data);
                const usersList = this.ownersList.length ? this.ownersList : [];
                const index = usersList.findIndex((userDt: { id: number, profilePicture: [{ thumbnailUrl: string, imageUrl: string }], firstName: string, lastName: string, email: string }) => userDt.id === this.taskDetails.lastEditedBy);
                const ownerCollaboratorIdx = this.taskDetails.taskUsers.findIndex((userDt: { id: number, isOwner: boolean, productProgramId: number, taskId: number, userId: number }) => userDt.userId === this.ownerId);
                if (ownerCollaboratorIdx >= 0) {
                    this.taskDetails.userIsTaskOwnerOrCollaborator = true;
                }
                if (index >= 0) {
                    this.taskDetails.lastEditor = usersList[index].profilePicture || [{ thumbnailUrl: '', imageUrl: '' }];
                    this.taskDetails.lastEditorFirstName = usersList[index].firstName || '';
                    this.taskDetails.lastEditorLastName = usersList[index].lastName || '';
                    this.taskDetails.lastEditorEmail = usersList[index].email || '';
                }
                this.openForm('view');
            }
        })
    }

    getEvents(programId: number, siteId?: number) {
        let payload: any = { programId: programId, productId: programConfigureStore.productId };
        payload['siteId'] = siteId;
        this.isEventLoading = true;
        plannerStore.events(payload).then((response: any) => {
            this.isEventLoading = false;
        })
            .catch(() => {
                this.isEventLoading = false;
            });
    }
    detailsEvent(event: any) {
        this.eventMode = 'View';
        this.calendarEventMode = this.eventMode;
        this.openForm('View')
        this.editEventPayloadForPrint = JSON.parse(JSON.stringify(event))
        this.editEventPayload = JSON.parse(JSON.stringify(event))
        this.viewEventId = this.editEventPayloadForPrint.id;
    }
    editEventHander() {
        this.eventMode = 'Edit'
        this.calendarEventMode = this.eventMode;
        this.isEventLoading = false;
    }
    emitMode(arg: string) {
        this.calendarEventMode = arg;
    }
    getSortedList(menu: any, index: number, type: string) {

        let column = menu.sortField;
        let order = menu.order;
        this.lastSorting = { order: order, sortField: column };

        if (!this.tasksList.length || !column) {
            return this.tasksList;
        }
        if (menu.sortField == 'taskUsers') {
            let newTaskList: any = [];
            let newTaskListUnsorted: any = [];
            this.tasksList.forEach((element: any) => {
                const ownerTask = element && element.taskUsers && element.taskUsers.filter((task: any) => task.isOwner);
                if (ownerTask && ownerTask.length) {
                    newTaskList.push(element)
                }
                else {
                    newTaskListUnsorted.push(element)
                }
            })
            this.newTaskListForSort = newTaskList.sort((a: any, b: any) => {
                let val1 = null;
                let val2 = null;

                a[column].forEach((el: any) => {

                    if (el.isOwner) {
                        val1 = el.user.firstName
                    }

                });

                b[column].forEach((el: any) => {

                    if (el.isOwner) {
                        val2 = el.user.firstName
                    }

                });

                return this.sortByOrder(order, this.toLower(val1), this.toLower(val2))
            });
            this.tasksList = this.newTaskListForSort.concat(newTaskListUnsorted)
        }
        else if (menu.sortField == 'taskEndDate') {

            this.tasksList = this.tasksList.sort(function (a: any, b: any) {
                let dateA: any = new Date(a[column]);
                let dateB: any = new Date(b[column]);

                if (!a[column] && b[column]) return 1
                else if (a[column] && !b[column]) return -1
                else if (dateA === dateB) return 0
                else {
                    if (!order || order == 2) {
                        return dateA - dateB;
                    }
                    else {
                        return dateB - dateA;
                    }
                }

            });
        }
        else if (menu.sortField == 'name' || menu.sortField == 'status') {
            this.tasksList = this.tasksList.sort((a: any, b: any) => {
                let val1 = this.toLower(a[column]);
                let val2 = this.toLower(b[column]);
                return this.sortByOrder(order, val1, val2)
            });
        }
        if (type == 'sort') {
            let data = this.getTasksMenuList;
            let updateIconData = this.sortIconsUpdate(data, index)
            this.getTasksMenuList = updateIconData;
        }
        this.lastSortingIndex = index;
        this.lastSorting = this.getTasksMenuList[index];
        this.setSortingDefault();
    }
    sortIconsUpdate(data: any, headerIndex: number) {
        for (let i = 0; i < data.length; i++) {
            if (i == headerIndex) {
                let currentOrder = data[i].order;
                data[i].order = currentOrder == 1 ? 2 : 1;
                this.order = data[i].order;
            } else {
                data[i].order = 0;
            }
        }
        return data;
    }
    toLower(input: any) {
        if (isNaN(input)) {
            return input.toLowerCase();
        }
        else {
            return input;
        }
    }
    sortByOrder(order: number, val1: any, val2: any) {
        if (!order || order == 2) {
            return ((val1 < val2) ? -1 : ((val1 > val2) ? 1 : ''));
        } else {
            return ((val1 > val2) ? -1 : ((val1 < val2) ? 1 : ''));
        }
    }
    updateSortTaskList() {
        let sortData = JSON.parse(JSON.stringify(this.lastSorting))
        if (sortData.order === 1) {
            sortData.order = 0
        }
        else {
            sortData.order = 1
        }

        this.getSortedList(JSON.parse(JSON.stringify(sortData)), this.lastSortingIndex, 'updateSorting')
    }
    viewEvent(eventId: number) {
        let tempEventList = JSON.parse(JSON.stringify(this.eventsList));
        tempEventList = tempEventList.filter((obj: any) => obj.id == eventId);
        this.eventMode = 'View'
        this.editEventPayload = JSON.parse(JSON.stringify(tempEventList[0]))
        this.editEventPayloadForPrint = JSON.parse(tempEventList[0])
        this.openForm('View')
    }
    calenderViewMode(view: any, weekDate: string) {
        this.checkCalenderViewType = view;
        if (this.checkCalenderViewType === 'months' && weekDate) {
            this.month = weekDate.split(' ')[0];
            this.year = (Number)(weekDate.split(' ')[1]);
        }
        this.weekDate = weekDate.split('-')[0] + ',' + weekDate.split(',')[1]
    }

    async makePDF() {
        this.isDownloadEventListViewInProgress = false;
        plannerStore.mutateDownloadingState(true)
        let breakHieght
        let scale
        if (!this.toggleListView) {
            breakHieght = this.checkCalenderViewType == 'months' ? 1200 : 1150
            scale = 1.7;
        } else {
            this.isDownloadEventListViewInProgress = true;
            breakHieght = 975
            scale = 1.2;
        }
        let threshold = 99
        let width = 0
        this.isPdfPrintInProgress = true
        let dateObj = new Date();
        let currentDate = APP_UTILITIES.getSlashedDates(dateObj);
        if (!this.month && this.year === 0) {
            this.month = APP_UTILITIES.fullMonthFormat(dateObj);
            this.year = dateObj.getFullYear();
        }
        currentDate = currentDate.replace(/-/g, "_");
        let fileName = this.toggleListView ? 'Event List_' + currentDate : this.checkCalenderViewType == 'months' ? "Event Calendar_" + this.month + ' ' + this.year : "Event Calendar_Week of " + this.weekDate
        let res = await Printutility.getPrintView('PrintingCalenderEvent', breakHieght * scale, width, scale, fileName, "HeaderCalenderEvent", threshold, this.toggleListView, false)
        this.isPdfPrintInProgress = false;
        this.isDownloadEventListViewInProgress = false;
    }

    setSortingDefault() {
        let createKey = this.authKey + "_task_planner_sort";
        let sortOrder = (this.order == 1 || this.order == 0) ? '1' : '2';
        this.lastSorting = this.getTasksMenuList.find((obj: any) => obj.order != 0);
        APP_UTILITIES.setSortingOnSession(createKey, this.lastSorting.label, sortOrder);
    }

    getSortingDefault() {
        let createKey = this.authKey + "_task_planner_sort";
        let sortVal: string | null = "";
        if (APP_UTILITIES.getSortingOnSession(createKey)) {
            sortVal = APP_UTILITIES.getSortingOnSession(createKey);
            let sortObj: { key: string, dir: string } = sortVal ? JSON.parse(sortVal) : {};

            this.getTasksMenuList.forEach((columnObj: any, columnObjIndex: number) => {
                if (columnObj.label === sortObj.key) {
                    let columnSelected = this.getTasksMenuList[columnObjIndex];
                    this.order = sortObj.dir == '1' ? 2 : 1;
                    columnObj.order = this.order;
                    this.lastSorting = this.getTasksMenuList[columnObjIndex];
                    this.lastSortingIndex = columnObjIndex;

                    this.getSortedList(columnSelected, columnObjIndex, 'sort');
                }
            })
        } else {
            this.setSortingDefault();
        }
    }
    async downloadSingleEventView(e: any, plannerEventObj: any) {
        this.isSingleEventDownloading = true
        let threshold = 99
        let width = this.isMobileView ? 1133 : 1144
        let breakHeight = 1050;
        let scale = 1.7
        let fileName = plannerEventObj.name
        let res = await Printutility.getPrintView('eventSinglePrintView', breakHeight * scale, width, scale, fileName, "HeaderCalenderEvent", threshold, true, false)
        this.isSingleEventDownloading = false
    }
    emitEventPrint(plannerEventObj: any) {
        /* istanbul ignore else */
        if (!this.isSingleEventDownloading) {
            this.editEventPayloadForPrint = JSON.parse(JSON.stringify(plannerEventObj))
        }
    }

    sortByProperty(property: any, order: string) {
        return function (a: any, b: any) {
            if (order === APP_CONST.ORDER_ASC_TEXT) {
                /* istanbul ignore if */
                if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase())
                    return 1;
                else if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase())
                    return -1;
            } else {
                /* istanbul ignore if */
                if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase())
                    return 1;
                else if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase())
                    return -1;
            }

            return 0;
        }
    }

    editDetailsEventHandler(event: any) {
        this.eventMode = 'Edit';
        this.calendarEventMode = this.eventMode;
        this.openForm('Edit')
        this.editEventPayload = JSON.parse(JSON.stringify(event))
    }

    destroyed() {
        LocalStorage.deleteFromLocalStorage("planner_tab_active");
        plannerStore.updateEventListData([]);
        this.isLocalSearch = false;
        this.isGlobalSearch = false;
    }

    addedSiteList(val:string){
        this.siteValue=val;
    }

    search(search:string){
        let teamRosterSearch = this.$refs && this.$refs.teamRosterSearch as any ;
        /* istanbul ignore next */
        if(teamRosterSearch){
            teamRosterSearch.blur();
        }
        this.searchBox = search;
        plannerStore.getPersistenceData();
        if((this.searchBox && this.searchBox.length ==  APP_CONST.ZERO) || (this.searchBox && this.searchBox.length >= APP_CONST.THREE)){
            this.globalSearch = true;
            this.isGlobalSearch = true;
            this.page= APP_CONST.ONE;
            this.searchLoader =  this.isLoaderVisible?true:this.searchLoader;
            this.teamrostertable()
        }
    }


    teamrostertable(){
        plannerStore.getPersistenceData();
        plannerStore.initlializeTeamRosterResponseStatusCode();
        this.isSearchRes = false
        this.isSearching = this.searchBox ? true:false;
        const payload = APP_CONST.TEAM_ROSTER_PAYLOAD
        let programId = APP_UTILITIES.getCookie("programId");
        let siteId = APP_UTILITIES.getCookie("siteId");
        payload.ProgramId = Number(programId);
        payload.SiteId = Number(siteId);
        payload.Id = APP_CONST.ZERO;
        payload.Active = APP_CONST.ZERO;
        payload.Search = this.searchBox;
        payload.RoleType =  APP_CONST.ZERO;
        payload.SearchField = this.searchFieldRoster;
        payload.SearchValue = this.searchValue;
        payload.Page = this.page;
        payload.Count =  this.isMobileForAPiCall ? 10 :APP_CONST.TWENTY_FIVE;
        payload.SortField = this.sortFieldRoster;
        payload.SortDir = this.sortDirRoster;
        payload.isExactMatch = this.isExactMatch;
        this.currentPayload =  payload;
        plannerStore.teamRosterList(payload).then((data)=>{
            this.searchLoader = false
        });
    }

    setPage(page: number){ 
        this.page=page;
        if(this.searchBox && this.searchBox.length < APP_CONST.THREE){
            this.searchBox = ''
        }
        this.teamrostertable();

    }

    clearSearch(search?:string) {
        if(search){
            this.searchBox = search;
        }
        this.globalSearch = false;
        this.isGlobalSearch = true;
        this.searchLoader =  this.isLoaderVisible?true:this.searchLoader;
        this.page= APP_CONST.ONE;
        this.searchBox = "";
        this.isSearching = false;
        plannerStore.getPersistenceData();
        this.teamrostertable();
    }

    viewTeamRosterDetails(rosterTableData:any){
        this.teamrosterresponse = rosterTableData
        this.siteSessionData = []
        this.pointerEventNone=true
        this.$emit(APP_CONST.NO_ACTION, this.pointerEventNone)
        if(UIkit.offcanvas('#team-roster-view-details')){
            UIkit.offcanvas('#team-roster-view-details').show();
        }
         let teamRosterResponse = this.teamrosterresponse
         let programIdroster = APP_UTILITIES.getCookie("programId");
         let siteIdroster = APP_UTILITIES.getCookie("siteId");
         teamRosterResponse.staffUser.userRoles && teamRosterResponse.staffUser.userRoles.forEach((element:any) => {
           if(Number(siteIdroster) !=0){
            if(element.siteName && element.siteId == siteIdroster){
                this.siteSessionData.push(element.siteName)
            }
           }
           else{
            if(element.siteName && element.programId == programIdroster){
                this.siteSessionData.push(element.siteName)
            }
           }
        });
    }
    filterColumns(displayColumn:any){
        let isSelected = false;
        displayColumn && displayColumn.length && displayColumn.forEach((column: any) => {
         if(column.display) {
            isSelected = true;
         }
      });
      this.displayALL = isSelected;
      this.clearAllColumns(this.displayALL)
        plannerStore.mutateTeamRosterFilteredObject(displayColumn);
        plannerStore.mutateSettingsData(displayColumn);
        plannerStore.getPersistenceData()
    }

    clearAllColumns(displayALL:boolean){
        let isSearchFieldFixed = this.team_roster_header.FIXED.map((e:any)=> { return e.value; }).indexOf(this.searchFieldRoster);
        let isSortFieldFixed = this.team_roster_header.FIXED.map((e:any)=> { return e.value; }).indexOf(this.sortField); 
        if(!displayALL && isSearchFieldFixed ==  APP_CONST.MINUS_ONE){
            this.searchValue = this.searchFieldRoster == APP_CONST.FIRST_NAME || this.searchFieldRoster == APP_CONST.LAST_NAME ? this.searchValue : ''
            this.searchFieldRoster = this.searchFieldRoster == APP_CONST.FIRST_NAME || this.searchFieldRoster == APP_CONST.LAST_NAME ? this.searchFieldRoster : ''
            this.teamrostertable();
            Object.assign(this.searchRoster,{ systemRole: "", email:"", inviteStatus:"", sitesessions:"", phoneNumber:"" })  
            plannerStore.rosterPayloadMut({...this.getRosterPayload, SearchField:this.searchFieldRoster,SearchValue: this.searchValue})
            plannerStore.mutateSitesSearchObj({searchField:this.searchFieldRoster, searchValues: this.searchValue});
  
        }
        if(!displayALL && isSortFieldFixed == APP_CONST.MINUS_ONE){
            this.sortDirRoster = this.sortFieldRoster == APP_CONST.FIRST_NAME || this.sortFieldRoster == APP_CONST.LAST_NAME ? this.sortDirRoster : APP_CONST.ZERO
            this.sortFieldRoster = this.sortFieldRoster == APP_CONST.FIRST_NAME|| this.sortFieldRoster == APP_CONST.LAST_NAME ? this.sortFieldRoster : ''
            this.teamrostertable();
            Object.assign(this.searchRoster,{ systemRole: "", email:"", inviteStatus:"", sitesessions:"", phoneNumber:"" })  
            plannerStore.rosterPayloadMut({...this.getRosterPayload,sortField: this.sortFieldRoster,sortDir: this.sortDirRoster})
            plannerStore.mutateSiteSortPayload({sortField: this.sortFieldRoster, sortDir: this.sortDirRoster});
        }
    }

    filterDisplayColumn(column:any){
        if(!column.display){
            if(column.value == this.getRosterPayload.SearchField){
                this.searchValue = ''
               this.searchFieldRoster = ''
               this.teamrostertable();
            }
            if(column.value == this.getRosterPayload.SortField){
                this.sortDirRoster = 0
                this.sortFieldRoster = ''
                this.teamrostertable();
            }
            this.searchRoster[column.value]='';
            plannerStore.rosterPayloadMut({...this.getRosterPayload, SearchField: (column.value == this.getRosterPayload.SearchField) ? '': this.getRosterPayload.SearchField, sortField: this.sortFieldRoster,sortDir: this.sortDirRoster, SearchValue: this.searchValue})
            plannerStore.mutateSiteSortPayload({sortField: this.sortFieldRoster, sortDir: this.sortDirRoster});
            plannerStore.mutateSitesSearchObj({searchField:this.searchFieldRoster, searchValues: this.searchValue});
        }
    }

    presistResize(resize: { column: any, maxWidth: any, minWidth: any }) {
        plannerStore.mutateResizeData(resize)
    }

    sortData(sortObj: { sortField: string, sortDirection: number}){
        this.sortFieldRoster = sortObj.sortField ;
        this.sortDirRoster = sortObj.sortDirection;
        this.searchLoader = this.isLoaderVisible?true:this.searchLoader;
        this.page = APP_CONST.ONE;
        plannerStore.rosterPayloadMut({...this.getRosterPayload, SortField: this.sortFieldRoster, SortDir: this.sortDirRoster,sortField: this.sortFieldRoster, sortDir:this.sortDirRoster })
        this.searchBox = this.searchBox ? this.searchBox: '';
        plannerStore.mutateSiteSortPayload({sortField: this.sortFieldRoster, sortDir: this.sortDirRoster})
        plannerStore.teamRosterListmut([]);
        this.teamrostertable();

    }
 
    clearAndcallApi(search?:string){
        if(search == ''){
            this.searchBox = search;
        }
        if(this.searchBox==''){
            this.searchLoader =  this.isLoaderVisible?true:this.searchLoader;
            this.teamrostertable();
        }
    }

    searchData(search:{searchField: string, isExactMatch: boolean}){
        this.localSearch = true;
        this.isLocalSearch = true;
        this.searchLoader = this.isLoaderVisible?true:this.searchLoader;
        this.renderTable = true;
        this.searchFieldRoster = search.searchField;
        this.isExactMatch = search.isExactMatch;
        this.searchValue = this.searchRoster[search.searchField] ? this.searchRoster[search.searchField] : '';
        this.page = APP_CONST.ONE;
        plannerStore.rosterPayloadMut({...this.getRosterPayload, SearchField: this.searchFieldRoster, SortDir: this.sortDirRoster, SearchValue : this.searchValue, isExactMatch: search.isExactMatch})
        this.teamRosterpayload.SearchField = search.searchField;
        this.searchBox = this.searchBox ? this.searchBox: '';
        this.teamrostertable();
        plannerStore.mutateSitesSearchObj({searchField:this.searchFieldRoster, searchValues: this.searchValue, isExactMatch: this.isExactMatch});
    }

    teamRosterReRenderTable(){
        setTimeout(() =>{
            plannerStore.getPersistenceData();  
            this.renderTable=true;},250);
    }

    downloadCsv(){
        this.disableDownloadButton = true;
        let notDisplayedColumns:Array<string> = [];
        let programId:string|null = APP_UTILITIES.getCookie('programId');
        let siteId:string|null = APP_UTILITIES.getCookie('siteId');
        let programName:string|null = APP_UTILITIES.getCookie('progName');
        let siteName:string|null = APP_UTILITIES.getCookie('siteName');
        this.displayColumns && this.displayColumns.forEach((item: any) => {
            if(item && !item.display){
                notDisplayedColumns.push(item.name)
            }
        });

        let payload = {
            programId: programId ? JSON.parse(programId) : APP_CONST.ZERO ,
            siteId: siteId ? JSON.parse(siteId) : APP_CONST.ZERO,
            search: this.currentPayload.Search,
            searchField : this.currentPayload.SearchField,
            searchValue : this.currentPayload.SearchValue,
            sortField : this.currentPayload.SortField,
            sortDir : this.currentPayload.SortDir,
            columnsToHide: notDisplayedColumns ? notDisplayedColumns.join(',') : "",
            programName: programName ? programName : '',
            siteName: siteName ? siteName : ''
        }

        DownloadTeamRosterCSV(payload).then((data)=>{
            if(data.status == 200){
                this.disableDownloadButton = false;
            }
        })


    }

    created(){
        this.isMobileForAPiCall = APP_UTILITIES.mobileAndTabletCheck();
    }

    persistSorting(){
        let authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
        let accountId: any = APP_UTILITIES.getCookie('accountId');
        let programId: any = APP_UTILITIES.getCookie('programId') ? APP_UTILITIES.getCookie('programId') : 0;
        let siteId:any = APP_UTILITIES.getCookie("siteId") ? APP_UTILITIES.getCookie("siteId") : 0;
        if (APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSortPayload`)) {
            let persistPayload: any = APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSortPayload`);
            persistPayload = JSON.parse(persistPayload);
            plannerStore.mutateAllSiteSortPayload(persistPayload)
            if (persistPayload.hasOwnProperty([accountId]) && persistPayload[accountId].hasOwnProperty([programId]) && persistPayload[accountId][programId].hasOwnProperty([siteId])) {
                return persistPayload[accountId][programId][siteId];
            }
            return "";
        }
        return "";
    }

    persistSearching(){
        let authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
        let accountId: any = APP_UTILITIES.getCookie('accountId');
        let programId: any = APP_UTILITIES.getCookie('programId') ? APP_UTILITIES.getCookie('programId') : 0;
        let siteId:any = APP_UTILITIES.getCookie("siteId") ? APP_UTILITIES.getCookie("siteId") : 0;
        if (APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSearchPayload`)) {
            let persistPayload: any = APP_UTILITIES.getFilteredColumns(`${authKey}-AllTeamRosterSearchPayload`);
            persistPayload = JSON.parse(persistPayload);
            plannerStore.mutateAllSiteSearchPayload(persistPayload)
            if (persistPayload.hasOwnProperty([accountId]) && persistPayload[accountId].hasOwnProperty([programId]) && persistPayload[accountId][programId].hasOwnProperty([siteId])) {
                return persistPayload[accountId][programId][siteId];
            }
            return "";
        }
        return "";
    }
   
}